<template>
  <el-dialog
    :visible.sync="dialogShow"
    :modal-append-to-body="true"
    :append-to-body="true"
    :modal="false"
    width="400px"
    @close="$emit('close')"
  >
    <div v-loading="loading" element-loading-text="svg生成中">
      <el-upload
        ref="upload"
        drag
        action="#"
        :http-request="loadPsd"
        :multiple="true"
        :show-file-list="false"
        accept=".psd"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将PSD文件拖到此处，或点击上传</div>
      </el-upload>
    </div>
    <canvas id="mycanvas" style="position: fixed; left: 100%"></canvas>
  </el-dialog>
</template>
<script>
import { readPsd, writePsd } from "ag-psd";
import potrace from "potrace";
import numeric from "numeric";
import OSS from "@/utils/oss.js";
import { apiAliToken } from "@/api/app";
import { getCurrentTime, randomString } from "@/utils/util.ts";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogShow: false,
      pathsData: {},
      faceImgData: {},
      loading: false,
      psdFile: "",
      ossClass: null,
    };
  },
  watch: {
    show: {
      handler(val) {
        this.dialogShow = val;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async loadPsd(event) {
      let res = await apiAliToken();
      this.ossClass = new OSS(res);
      let that = this;
      const file = event.file;
      if (file) {
        this.loading = true;
        const reader = new FileReader();
        reader.onload = (e) => {
          const buffer = e.target.result;
          const psd = readPsd(buffer, {
            useImageData: false,
          });

          let svgContent = `<svg id="mySvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 ${psd.width} ${psd.height}"
                                    width="${psd.width}" height="${psd.height}">
                                    <defs>`;
          svgContent += "\n";
          let gContent = "",
            pathContent = "",
            imgContent = "";
          psd.children.reverse();
          (async function f(index) {
            if (index < psd.children.length) {
              let layer = psd.children[index];
              if (!layer.hidden) {
                if (layer.children) {
                  for (let smart of layer.children) {
                    // 将蒙版应用到图片上
                    if (smart.placedLayer && !smart.hidden) {
                      let vertexArr = smart.placedLayer.transform;
                      const rotatedPoints = [
                        [vertexArr[0], vertexArr[1]], // 第一个顶点坐标
                        [vertexArr[2], vertexArr[3]], // 第二个顶点坐标
                        [vertexArr[4], vertexArr[5]], // 第三个顶点坐标
                        [vertexArr[6], vertexArr[7]], // 第四个顶点坐标
                      ];
                      let smartName;
                      if (smart.name.indexOf("-") > -1) {
                        smartName = smart.name.split("-")[0];
                      } else {
                        smartName = smart.name;
                      }

                      const originalPoints = [
                        [0, 0], // 原始图像左上角
                        [1200, 0], // 原始图像右上角
                        [1200, 1200], // 原始图像右下角
                        [0, 1200], // 原始图像左下角
                      ];
                      let res = that.computePerspectiveMatrix(
                        originalPoints,
                        rotatedPoints
                      );
                      let matrix = that.matrixToCSSMatrix(res);
                      // 使用示例
                      const placedLayer_id = `factory-img-${
                        parseInt(index) + 1
                      }`;

                      imgContent += `<image crossOrigin="anonymous" id="${placedLayer_id}"
                                       href="http://fq-design.oss-cn-shanghai.aliyuncs.com/factory/sucai${
                                         parseInt(index) + 1
                                       }.png"
                                       xlink:href="http://fq-design.oss-cn-shanghai.aliyuncs.com/factory/sucai${
                                         parseInt(index) + 1
                                       }.png"
                                       width="1200" height="1200" />`;
                      imgContent += "\n";

                      gContent += `<g id="${
                        layer.name
                      }" clip-path="url(#clip-path${parseInt(index) + 1})">
                                       <use id="${smartName}" style="opacity: ${
                        smart.opacity
                      }; mix-blend-mode: ${
                        smart.blendMode
                      }" href="#${placedLayer_id}" xlink:href="#${placedLayer_id}"
                                         transform="${matrix}" />
                                     </g>`;
                      gContent += "\n";
                    }
                    if (layer.mask && !layer.hidden) {
                      pathContent += `<clipPath clipPathUnits="userSpaceOnUse" id="clip-path${
                        parseInt(index) + 1
                      }">`;
                      pathContent += "\n";
                      pathContent += await that.toSvg(layer.mask);
                      pathContent += "\n";
                      pathContent += `</clipPath>`;
                    }
                  }
                } else {
                  let base64img = layer.canvas.toDataURL();
                  imgContent += `<image crossOrigin="anonymous" id="image${
                    parseInt(index) + 1
                  }"
                       href="${base64img}"
                       xlink:href="${base64img}"
                       width="${layer.canvas.width}" height="${
                    layer.canvas.height
                  }" />`;
                  gContent += `<use id="${layer.name}" style="opacity: ${
                    layer.opacity
                  }; mix-blend-mode: ${layer.blendMode}" xlink:href="#image${
                    parseInt(index) + 1
                  }" href="#image${parseInt(index) + 1}" x="${layer.left}" y="${
                    layer.top
                  }" />`;
                  gContent += "\n";
                }
              }
              f(index + 1);
            } else {
              svgContent += imgContent;
              svgContent += "\n";
              svgContent += pathContent;
              svgContent += "</defs>";
              svgContent += "\n";
              svgContent += gContent;
              svgContent += "\n";
              svgContent += "</svg>";
              that.createSvgFile(svgContent, file);
            }
          })(0);
        };
        reader.readAsArrayBuffer(file);
      }
    },

    //计算矩阵
    computePerspectiveMatrix(srcCoords, dstCoords) {
      const [p1, p2, p3, p4] = srcCoords;
      const [q1, q2, q3, q4] = dstCoords;

      const A = [
        [p1[0], p1[1], 1, 0, 0, 0, -q1[0] * p1[0], -q1[0] * p1[1]],
        [0, 0, 0, p1[0], p1[1], 1, -q1[1] * p1[0], -q1[1] * p1[1]],

        [p2[0], p2[1], 1, 0, 0, 0, -q2[0] * p2[0], -q2[0] * p2[1]],
        [0, 0, 0, p2[0], p2[1], 1, -q2[1] * p2[0], -q2[1] * p2[1]],

        [p3[0], p3[1], 1, 0, 0, 0, -q3[0] * p3[0], -q3[0] * p3[1]],
        [0, 0, 0, p3[0], p3[1], 1, -q3[1] * p3[0], -q3[1] * p3[1]],

        [p4[0], p4[1], 1, 0, 0, 0, -q4[0] * p4[0], -q4[0] * p4[1]],
        [0, 0, 0, p4[0], p4[1], 1, -q4[1] * p4[0], -q4[1] * p4[1]],
      ];

      const B = [q1[0], q1[1], q2[0], q2[1], q3[0], q3[1], q4[0], q4[1]];

      // 使用线性代数求解器计算矩阵
      const h = numeric.solve(A, B);

      // 构建透视矩阵
      const H = [
        [h[0], h[1], h[2]],
        [h[3], h[4], h[5]],
        [h[6], h[7], 1], // 注意这里最后一行是 1
      ];
      return H;
    },

    matrixToCSSMatrix(affineMatrix) {
      const a = affineMatrix[0][0]; // h11
      const b = affineMatrix[1][0]; // h21
      const c = affineMatrix[0][1]; // h12
      const d = affineMatrix[1][1]; // h22
      const tx = affineMatrix[0][2]; // h13
      const ty = affineMatrix[1][2]; // h23

      return `matrix(${a}, ${b}, ${c}, ${d}, ${tx}, ${ty})`;
    },

    buildMask(psd, layer) {
      return new Promise((resolve, reject) => {
        console.log(layer);
        const canvas = document.createElement("canvas");
        canvas.id = "mycanvas";
        canvas.width = psd.width;
        canvas.height = psd.height;
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, psd.width, psd.height);
        ctx.fillRect(0, 0, psd.width, psd.height);
        let mask = new Image();
        mask.onload = async () => {
          ctx.drawImage(
            mask,
            layer.mask.left,
            layer.mask.top,
            layer.mask.canvas.width,
            layer.mask.canvas.height
          );
          let value = canvas.toDataURL();
          canvas.remove();
          resolve(value);
        };
        mask.src = layer.mask.canvas.toDataURL();
      });
    },
    toSvg(mask) {
      return new Promise((resolve, reject) => {
        let that = this;
        let trace = new potrace.Potrace();
        trace.setParameters({
          //   threshold: 255,
          color: "",
          blackOnWhite: false,
        });
        trace.loadImage(mask.canvas.toDataURL(), function (err) {
          if (err) throw err;
          let result = that.handleSvg(trace.getPathTag(), mask.left, mask.top);
          resolve(result);
        });
      });
    },

    handleSvg(svgString, leftVal, topVal) {
      return new Promise((resolve, reject) => {
        let matches = svgString.match(/(d=)"([^"]+)"/i);
        let newCommands = [];
        let commands = matches[2].trim("").split(/([MmLlHhVvCcSsQqTtAaZz])/);
        for (let i = 0; i < commands.length; i += 1) {
          let path = commands[i].trim("").split(",");
          if (path.length > 1) {
            let newArr = [];
            for (let j = 0; j < path.length; j++) {
              let arr = path[j].trim("").split(" ");
              let childArr = this.setPointNewPosition(arr, leftVal, topVal);
              newArr.push(childArr.join(" "));
            }
            newCommands.push(newArr.join(" "));
          } else {
            if (path[0].length <= 1) {
              newCommands.push(commands[i]);
            } else {
              let arr = path[0].split(" ");
              let newArr = this.setPointNewPosition(arr, leftVal, topVal);
              newCommands.push(newArr.join(" "));
            }
          }
        }
        let str = `<path d="${newCommands
          .join(" ")
          .trim("")}" stroke="none" fill="" fill-rule="evenodd"/>`;
        resolve(str);
      });
    },
    setPointNewPosition(arr, leftVal, topVal) {
      let newArr = [];
      for (let j = 0; j < arr.length; j++) {
        if (j % 2 == 0) {
          newArr.push((Number(arr[j]) + leftVal).toFixed(3));
        } else {
          newArr.push((Number(arr[j]) + topVal).toFixed(3));
        }
      }
      return newArr;
    },
    async createSvgFile(svgContent, file) {
      // 创建一个Blob对象
      const blob = new Blob([svgContent], {
        type: "image/svg+xml;charset=utf-8",
      });
      let svg = await this.ossClass.putBlobToOss(
        blob,
        "factory",
        `svg/${getCurrentTime(1)}/${randomString(10, 2)}.svg`
      );
      this.$emit("confirm", svg);
      this.loading = false;
      this.$message.success("svg生成成功");
      setTimeout(() => {
        this.$emit("close");
      }, 1000);
      // 创建一个指向Blob的URL
      // const url = URL.createObjectURL(blob);
    },

    downLoad(url, fileName = "") {
      let oA = document.createElement("a");
      oA.download = fileName || ""; // 设置下载的文件名，默认是'下载'
      oA.href = url;
      document.body.appendChild(oA);
      oA.click();
      oA.remove(); // 下载之后把创建的元素删除
    },
  },
};
</script>
