
import { Component, Vue, Watch } from "vue-property-decorator";
import AddPrice from "@/components/model/add-price.vue";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";
import ModelDetails from "@/components/model/details.vue";
import ModelAttrs from "@/components/model/attrs.vue";
import ThreePreview from "@/components/material-select/three-preview.vue";

import {
  apiModelList,
  apiModelDetail,
  apiModelCategoryList,
  apiModelAdd,
  apiModelEdit,
} from "@/api/model.ts";

@Component({
  components: {
    MaterialSelect,
    AddPrice,
    ModelAttrs,
    ModelDetails,
    ThreePreview,
  },
})
export default class ModelEdit extends Vue {
  $refs!: { form: any };
  /** S Data **/
  mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色
  pager: any;
  searchObj: any;
  // 表
  lists: Array<object> = [];
  activeName = "basic";
  categoryList: any = [];
  model_url: any = {
    url: "",
    scale: "16,16,16",
    rotation: "0,0,0",
    position: "0,0,0",
  };

  // 表单数据
  form: any = {
    code: "",
    zh_cn_title: "",
    en_title: "",
    image: "",
    images: [],
    product_number: "",
    zh_cn_material: "",
    en_material: "",
    zh_cn_technology: "",
    en_technology: "",
    cate_id: "",
    is_three: 0,
    spec_design: 0,
    status: 1,
    model_url: "",
    animate_model: "",
    sort: 1,
    scale: "16,16,16",
    rotation: "0,0,0",
    position: "0,0,0",
    remark: "",
    spec_all_chose: 1,
    factoryimg_type: 1,
    spec_value_list: [
      {
        id: "",
        image: "",
        cost_price: "",
        volume: "",
        weight: "",
        width: "",
        length: "",
        high: "",
        img_suffix: "jpeg",
      },
    ],
    fast_design: 1,
    connected: 1,
    fast_design_id: "",
    attrs: [],
  };

  connected_model = [];
  modelList = [];

  // 表单校验
  rules: object = {
    zh_cn_title: [{ required: true, message: "请输入名称", trigger: "blur" }],
  };

  // 必传字段与对应的选项卡名字
  requireFields = [
    {
      fields: ["code", "name", "category_id", "goods_image"],
      activeName: "basic",
    },
    {
      fields: [
        "spec_value_list[0].stock",
        "spec_value_list[0].factory_price",
        "spec_value_list[0].sell_price",
        "spec_value_list[0].length",
        "spec_value_list[0].width",
        "spec_value_list[0].high",
      ],
      activeName: "price",
    },
  ];

  // 规格绑定的数据
  specData: any = {
    spec_type: 1,
    spec_value: [],
    spec_value_list: [],
  };

  attrData: any = [];

  storeList: any[] = [];

  modelArr: any = [];

  /** E Data **/
  @Watch("specData", { deep: true })
  specDataChange(val: any) {
    this.form.spec_value_list = val.spec_value_list;
    this.form.spec_value = val.spec_value;
  }

  @Watch("attrData", { deep: true })
  attrDataChange(val: any) {
    this.form.attrs = typeof val === "string" ? JSON.parse(val) : val;
  }

  /** S Methods **/
  getCategory() {
    apiModelCategoryList({}).then((res) => {
      this.categoryList = res.lists;
    });
  }

  getDetail() {
    apiModelList({
      connected: 2,
      fast_design: 2,
      page_no: 1,
      page_size: 99,
    }).then((res) => {
      this.modelList = res.lists;
    });
    apiModelDetail({ id: this.form.id }).then((res) => {
      this.form = res;
      this.model_url = JSON.parse(this.form.model_url);
      if (!Array.isArray(this.model_url)) {
        this.modelArr = [this.model_url];
      } else {
        this.modelArr = this.model_url;
      }
      this.initData(res);
    });
  }

  // 初始化规格数据
  initData(data: any = {}) {
    const spec_value = [
      {
        has_image: false,
        id: "",
        name: "",
        spec_list: [
          {
            id: "",
            value: "",
            image: "",
          },
        ],
      },
    ];
    const spec_value_list = [
      {
        id: "",
        image: "",
        sell_price: "",
        lineation_price: "",
        cost_price: "",
        stock: "",
        volume: "",
        weight: "",
        bar_code: "",
      },
    ];

    const specData: any = {
      spec_value: data.spec_value || spec_value,
      spec_value_list: data.spec_value_list || spec_value_list,
    };
    specData.spec_value.forEach((item: any) => {
      item.has_image = false;
      item.spec_list.forEach((sitem: any) => {
        sitem.image = "";
      });
    });

    Object.assign(this.specData, specData);
    // this.loading = false

    this.attrData = data.attrs;
  }
  /** E Methods **/

  /** S Life Cycle **/
  mounted() {
    this.$nextTick(() => {
      this.$refs.form.clearValidate();
    });
  }

  handleSubmit() {
    if (!this.form.spec_value || this.form.spec_value.length !== 2) {
      this.$message.error("请完善规格");
      return;
    }
    for (let spce of this.form.spec_value) {
      for (let item of spce.spec_list) {
        if (!item.value) {
          this.$message.error("规格值不能为空");
          return;
        }
      }
    }
    this.form.model_url = JSON.stringify(this.modelArr);
    if (this.mode === PageMode.EDIT) {
      apiModelEdit(this.form).then((res: any) => {
        setTimeout(() => {
          this.getDetail();
        }, 500);
      });
    } else {
      apiModelAdd(this.form).then((res: any) => {
        setTimeout(() => {
          location.href = `/platform/model/model_edit?id=${res.id}&mode=edit`;
        }, 1000);
      });
    }
  }

  back() {
    this.$router.go(-1);
  }

  addModel() {
    this.modelArr.push({
      url: "",
      scale: "16,16,16",
      rotation: "0,0,0",
      position: "0,0,0",
    });
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;
    if (query.mode) this.mode = query.mode;
    // this.pager = this.$route.params.pager as any;
    // this.searchObj = this.$route.params.searchObj as any;
    this.getCategory();
    if (this.mode === PageMode.EDIT) {
      this.form.id = query.id * 1;
      this.getDetail();
    }
  }

  /** E Life Cycle **/
}
